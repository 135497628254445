// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agosto-js": () => import("../src/pages/agosto.js" /* webpackChunkName: "component---src-pages-agosto-js" */),
  "component---src-pages-brecha-js": () => import("../src/pages/brecha.js" /* webpackChunkName: "component---src-pages-brecha-js" */),
  "component---src-pages-comu-js": () => import("../src/pages/comu.js" /* webpackChunkName: "component---src-pages-comu-js" */),
  "component---src-pages-diciembre-js": () => import("../src/pages/diciembre.js" /* webpackChunkName: "component---src-pages-diciembre-js" */),
  "component---src-pages-elements-js": () => import("../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-mcu-js": () => import("../src/pages/mcu.js" /* webpackChunkName: "component---src-pages-mcu-js" */),
  "component---src-pages-siervos-js": () => import("../src/pages/siervos.js" /* webpackChunkName: "component---src-pages-siervos-js" */),
  "component---src-pages-tutorial-js": () => import("../src/pages/tutorial.js" /* webpackChunkName: "component---src-pages-tutorial-js" */)
}

